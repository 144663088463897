<template>
    <mercur-card class="full-height fill container global-holidays px-4 center">
        <div v-if="success">
            <h2 class="font-weight-normal">Updating holidays was scheduled.</h2>
            <p>The holidays are being imported now. This can take a few minutes.</p>
            <mercur-button class="btn btn-raised btn-yellow text-uppercase" @click="reInitialize()">Import more holidays</mercur-button>
        </div>
        <mercur-stepper v-else class="full-height fill" :is-full="true" :active-step.sync="activeStep">
            <mercur-step id="country" title="Country" disabled>
                <template v-if="isAllowedTo('addLocationHoliday')">
                    <p>Please select the country and year you would like to insert holidays for. Click continue after selecting the country.</p>
                    <div class="w-50">
                        <mercur-select v-model="selectedCountry" @keypress.enter.prevent="() => {}">
                            <template slot="label">Select a country</template>
                            <option
                                v-for="(country, key) in availableCountries"
                                :key="key"
                                :value="country">
                                {{country.countryName}}
                            </option>
                        </mercur-select>
                        <mercur-select v-model="selectedYear" @keypress.enter.prevent="() => {}">
                            <template slot="label">Select a year</template>
                            <option
                                v-for="(year, key) in availableYears"
                                :key="key"
                                :value="year">
                                {{year}}
                            </option>
                        </mercur-select>
                    </div>
                    <portal to="actionbar" v-if="activeStep === 'country'">
                        <transition name="slide">
                            <div v-if="stepCountryDone" class="card-padding">
                                <mercur-button class="btn btn-raised btn-yellow text-uppercase ml-3" @click="activeStep = 'locations'" :disabled="!stepCountryDone">Continue</mercur-button>
                            </div>
                        </transition>
                    </portal>
                </template>
                <p class="permission-message" v-else>You are not allowed to add global holidays</p>
            </mercur-step>

            <mercur-step id="locations" title="Locations" disabled>
                <p v-if="!filteredLocations || !filteredLocations.length" class="pl-2">No locations found for <strong v-if="selectedCountry">{{selectedCountry.countryName}}</strong>. Please select another country</p>
                <template v-else>
                    <p class="ml-2">Please select the locations from <strong v-if="selectedCountry">{{selectedCountry.countryName}}</strong> you want to apply</p>
                    <div class="grid-container">
                        <ag-grid-vue
                            class="ag-grid fill ag-theme-material border"
                            :columnDefs="columnDefsLocations"
                            :defaultColDef="defaultColDef"
                            :animateRows="true"
                            rowModelType="clientSide"
                            :rowData="filteredLocations"
                            :pagination="true"
                            :enableRangeSelection="false"
                            :suppressCellSelection="true"
                            @model-updated="onModelUpdated"
                            @grid-ready="onGridReadyLocations"
                            @cell-value-changed="onCellValueChangedLocations"
                        ></ag-grid-vue>
                    </div>
                </template>
                <portal to="actionbar" v-if="activeStep === 'locations'" disabled>
                    <transition name="slide">
                        <div v-if="stepLocationsDone" class="card-padding">
                            <mercur-button class="btn btn-raised btn-yellow text-uppercase ml-3" @click="activeStep = 'holidays'" :disabled="!stepLocationsDone">Continue</mercur-button>
                        </div>
                    </transition>
                </portal>
            </mercur-step>

            <mercur-step id="holidays" title="Holidays" disabled>
                <p class="ml-2">Please select the holidays from <strong v-if="selectedCountry">{{selectedCountry.countryName}}</strong> you want to apply</p>
                <div class="grid-container">
                    <ag-grid-vue
                        class="ag-grid fill ag-theme-material border"
                        :columnDefs="columnDefsHolidays"
                        :defaultColDef="defaultColDef"
                        :animateRows="true"
                        rowModelType="clientSide"
                        :rowData="availableHolidays"
                        :pagination="true"
                        :enableRangeSelection="false"
                        :suppressCellSelection="true"
                        @model-updated="onModelUpdated"
                        @grid-ready="onGridReadyHolidays"
                        @cell-value-changed="onCellValueChangedHolidays"
                    ></ag-grid-vue>
                </div>
                <portal to="actionbar" v-if="activeStep === 'holidays'">
                    <transition name="slide">
                        <div v-if="stepHolidaysDone" class="card-padding">
                            <mercur-button class="btn btn-raised btn-yellow text-uppercase ml-3" @click="activeStep = 'confirmation'" :disabled="!stepHolidaysDone">Continue</mercur-button>
                        </div>
                    </transition>
                </portal>
            </mercur-step>

            <mercur-step id="confirmation" title="Confirmation" disabled>
                <p>When clicking the confirm button the <strong>{{selectedHolidays.length}}</strong> selected holidays will be applied to the <strong>{{selectedLocations.length}}</strong> selected locations</p>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-6">
                            <div class="text-secondary">Supplier/locations</div>
                            <ul>
                                <li v-for="(supplier, key) in selectedLocationsGroupedBySupplier" :key="key">
                                    {{supplier.supplierName}}
                                    <ul>
                                        <li v-for="(location, locationKey) in supplier.locations" :key="locationKey">
                                            {{location.address.locationName}}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="col-6">
                            <div class="text-secondary">Holidays</div>
                            <ul>
                                <li v-for="(holiday, key) in selectedHolidays" :key="key">
                                    {{holiday.name}} ({{holiday.date.iso | asDate}})
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <portal to="actionbar" v-if="activeStep === 'confirmation'">
                    <transition name="slide">
                        <div class="card-padding">
                            <mercur-button class="btn btn-raised btn-yellow text-uppercase ml-3" v-if="isAllowedTo('addLocationHoliday')" @click="submitBulkSetHolidays" :disabled="loading">Confirm</mercur-button>
                        </div>
                    </transition>
                </portal>
            </mercur-step>
        </mercur-stepper>
    </mercur-card>
</template>

<script>

import CONFIG from '@root/config'
import moment from 'moment'
import regions from 'country-region-data'

import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'

export default {
    name: 'GlobalHolidaysView',
    components: { AgGridVue },

    data () {
        return {
            success: false,
            loading: false,
            activeStep: 'country',
            columnDefsHolidays: null,
            columnDefsLocations: null,
            gridApiHolidays: null,
            gridApiLocations: null,
            defaultColDef: null,
            availableCountries: regions,
            allLocations: null,
            availableYears: Array.from({ length: 10 }, (_, i) => (new Date()).getFullYear() + i),
            availableHolidays: null,
            selectedYear: (new Date()).getFullYear(),
            selectedCountry: null,
            selectedHolidays: [],
            selectedLocations: [],
        }
    },

    computed: {
        stepCountryDone () {
            return this.selectedCountry !== null && this.selectedYear !== null
        },
        stepLocationsDone () {
            return this.stepCountryDone && this.selectedLocations.length > 0
        },
        stepHolidaysDone () {
            return this.stepLocationsDone && this.selectedHolidays.length > 0
        },
        stepConfirmationDone () {
            return this.stepHolidaysDone
        },
        filteredLocations () {
            if (this.allLocations === null || this.selectedCountry === null) {
                return null
            }

            return this.allLocations.filter(location => location.countryCode === this.selectedCountry.countryShortCode)
        },
        selectedLocationsGroupedBySupplier () {
            return this.selectedLocations
                .filter((location, index, list) => list.findIndex(otherLocation => otherLocation.supplierId === location.supplierId) === index)
                .flatMap(supplier => {
                    return {
                        supplierName: supplier.supplierName,
                        supplierId: supplier.supplierId,
                        locations: this.selectedLocations.filter(location => location.supplierId === supplier.supplierId),
                    }
                })
        },
    },

    beforeMount () {
        this.columnDefsHolidays = [
            {
                headerName: 'Selected',
                field: 'selected',
                width: 70,
                cellRendererFramework: 'CheckboxCell',
                headerComponentFramework: 'CheckboxCell',
                headerComponentParams: () => {
                    return {
                        disabled: this.loading,
                        setValue: (newValue) => {
                            if (!newValue) {
                                this.selectedHolidays = []
                            }

                            this.availableHolidays.forEach(holiday => {
                                holiday.selected = newValue
                                if (newValue) {
                                    this.selectedHolidays.push(holiday)
                                }
                            })

                            setTimeout(() => {
                                this.gridApiHolidays.redrawRows()
                            }, 0)
                        },
                        getValue: () => {
                            if (this.selectedHolidays === null || this.selectedHolidays.length === 0) {
                                return false
                            }
                            return !this.selectedHolidays.some(holiday => !this.selectedHolidays.includes(holiday))
                        },
                    }
                },
            },
            {
                field: 'name',
            },
            {
                headerName: 'Date',
                field: 'date',
                valueFormatter: ({ value }) => {
                    return this.$options.filters.asDate(value.iso)
                },
            },
        ]

        this.columnDefsLocations = [
            {
                headerName: 'Selected',
                field: 'selected',
                width: 70,
                cellRendererFramework: 'CheckboxCell',
                headerComponentFramework: 'CheckboxCell',
                headerComponentParams: () => {
                    return {
                        disabled: this.loading,
                        setValue: (newValue) => {
                            if (!newValue) {
                                this.selectedLocations = []
                            }

                            this.filteredLocations.forEach(location => {
                                location.selected = newValue
                                if (newValue) {
                                    this.selectedLocations.push(location)
                                }
                            })

                            setTimeout(() => {
                                this.gridApiLocations.redrawRows()
                            }, 0)
                        },
                        getValue: () => {
                            if (this.selectedLocations === null || this.selectedLocations.length === 0) {
                                return false
                            }
                            return !this.selectedLocations.some(location => !this.selectedLocations.includes(location))
                        },
                    }
                },
            },
            {
                field: 'supplierName',
            },
            {
                field: 'address.locationName',
                headerName: 'Location Name',
            },
        ]

        this.defaultColDef = {
            sortable: true,
            resizable: true,
        }
    },

    methods: {
        reInitialize () {
            this.selectedHolidays = []
            this.selectedLocations = []
            this.selectedCountry = null
            this.activeStep = 'country'
            this.selectedYear = (new Date()).getFullYear()
            this.success = false
        },
        getHolidaysForCountryAndYear (countryCode, year) {
            const url = CONFIG.API.ROUTES.HOLIDAYS.BY_COUNTRY_AND_YEAR.replace('{countryCode}', countryCode).replace('{year}', year)
            this.addJob(url)

            this.$api.get(url).then(({ data }) => {
                this.availableHolidays = data.holidays.filter(holiday => holiday.type.includes('National holiday'))
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Something went wrong while getting holidays. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
        onCellValueChangedHolidays () {
            this.selectedHolidays = this.availableHolidays.filter(holiday => holiday.selected).sort()
        },
        onCellValueChangedLocations () {
            this.selectedLocations = this.allLocations.filter(holiday => holiday.selected).sort()
        },
        onGridReadyHolidays (params) {
            this.gridApiHolidays = params.api
            setTimeout(() => {
                this.gridApiHolidays.sizeColumnsToFit()
            }, 100)
            window.addEventListener('resize', () => {
                this.gridApiHolidays.sizeColumnsToFit()
            })
        },
        onGridReadyLocations (params) {
            this.gridApiLocations = params.api
            setTimeout(() => {
                this.gridApiLocations.sizeColumnsToFit()
            }, 100)
            window.addEventListener('resize', () => {
                this.gridApiLocations.sizeColumnsToFit()
            })
        },
        onModelUpdated () {
            if (this.gridApiLocations) {
                this.gridApiLocations.sizeColumnsToFit()
            }
            if (this.gridApiLocations) {
                this.gridApiLocations.sizeColumnsToFit()
            }
        },

        submitBulkSetHolidays () {
            if (!this.isAllowedTo('addLocationHoliday')) {
                return
            }
            const url = CONFIG.API.ROUTES.SUPPLIERS.LOCATION_SET_BULK_HOLIDAYS

            const params = {
                locations: this.selectedLocations,
                holidays: this.selectedHolidays.map(holiday => {
                    const date = moment(holiday.date.iso)
                    return {
                        holidayName: holiday.name,
                        holidayFrom: date.format('YYYY-MM-DD 00:00:00'),
                        holidayTo: date.format('YYYY-MM-DD 23:59:59'),
                        yearly: false,
                        description: holiday.description,
                        type: holiday.type,
                    }
                }),
            }

            this.loading = true
            this.addJob(url)
            this.$api.post(url, params).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Applying holidays to locations was scheduled and will be processed soon',
                })
                this.success = true
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Applying holidays failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(url)
                this.loading = false
            })
        },
    },
    watch: {
        activeStep (step) {
            if (step === 'holidays') {
                this.gridApiHolidays.sizeColumnsToFit()
                return
            }

            if (step === 'locations') {
                this.gridApiHolidays.sizeColumnsToFit()
            }
        },
    },
    created () {
        this.$watch(() => {
            return [this.selectedYear, this.selectedCountry]
        }, () => {
            if (this.selectedYear === null || this.selectedCountry === null) {
                return
            }

            this.getHolidaysForCountryAndYear(this.selectedCountry.countryShortCode, this.selectedYear)
        })

        if (!this.isAllowedTo('getLocationsWithAddress')) {
            return
        }
        const url = CONFIG.API.ROUTES.SUPPLIERS.ALL_LOCATIONS
        this.addJob(url)

        this.$api.get(url).then(({ data }) => {
            this.allLocations = data.data
        }).finally(() => {
            this.finishJob(url)
        })
    },
}
</script>

<style lang="scss" scoped>
    .global-holidays {
        max-width: 1200px;
    }
    .grid-container {
        height: 100%;
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;

        &--scroll {
            overflow-y: auto;
        }
        &--confirmation {
            margin-bottom: 50px;
        }
    }
</style>
